<template>
  <div>
    <div class="ui-type-display-lg mb-3">
      {{ $t("user.marketCart.myOrder") }}
    </div>
    <div class="d-flex justify-content-center" v-if="loading">
      <CSpinner color="info" />
    </div>

    <MarketOrder v-if="order" :order="order" :viewDetails="true" />
  </div>
</template>

<script>
import { GetDataManagerNew, ApiCall } from "../../ds";
import { Query } from "@syncfusion/ej2-data";
import { mapGetters } from "vuex";
import { MarketOrder } from "./partials";

export default {
  name: "UiMarketOrderDetail",
  components: {
    MarketOrder,
  },

  data() {
    return {
      loading: false,
      order: null,
    };
  },

  computed: {
    ...mapGetters("connections", ["familyId"]),
  },

  mounted() {
    this.getOrder();
  },

  methods: {
    getOrder() {
      const self = this;
      self.loading = true;
      const query = new Query();
      const orderId = this.$route.params.orderId;
      ApiCall(
        GetDataManagerNew("family_orders", [this.familyId], orderId),
        query,
        (response) => {
          self.order = response.result;
          self.loading = false;
        },
        (e) => {
          console.error(e);
          self.loading = false;
        }
      );
    },

    changeZipCode() {},
  },
};
</script>
